@charset "utf-8";

/**************************************************************
    인풋 폼
**************************************************************/

.ddp-ui-input-form {
  display: block;
  text-align: left;

  label.ddp-label-type {
    display: block;
    padding-bottom: 5px;
    font-size: 13px;
    color: #444;

    span {
      &.ddp-label {
        display: block;
        float: left;
        width: 110px;
      }

      &.ddp-txt-option {
        display: inline-block;
        margin-left: 6px;
        color: #9c9c9c;
        font-size: 12px;
        letter-spacing: -1px;
      }
    }

    .ddp-ui-tooltip:hover .ddp-ui-tooltip-info {
      display: block;
    }

    .ddp-ui-tooltip-info {
      display: none;
    }
  }

  input.ddp-input-type {
    display: block;
    width: 100%;
    padding: 10px 0;
    font-size: 16px;
    border: none;
    border-bottom: 1px solid #ddd;
  }

  label.ddp-label-type span.ddp-info2 {
    display: block;
    font-size: 13px;
    color: #4b515b;
    font-weight: normal;
    overflow: hidden;

    .ddp-data-group {
      display: block;
      padding-bottom: 10px;
      font-size: 13px;
      color: #4b515b;
    }

    .ddp-data-det {
      display: block;
      color: #b7bac1;
      font-size: 12px;
    }

    .ddp-data-group {
      .ddp-icon-user-s2,
      .ddp-icon-group-s2 {
        margin-right: 5px;
        opacity: 1;
      }
    }
  }

  .ddp-data-form {
    position: relative;
    padding: 10px 0;
    font-size: 16px;

    .ddp-btn-selection {
      position: absolute;
      top: 50%;
      right: 0;
      margin-top: -17px;
    }

    input[type='password'] {
      display: block;
      width: 100%;
      font-size: 16px;
      border: none;
    }
  }

  &.ddp-type-error .ddp-input-check {
    border-bottom: 1px solid #ff2a1b;

    .ddp-icon-error {
      display: block;
    }
  }
}

[class*='ddp-col'].ddp-apply {
  position: relative;
  padding-right: 52px;

  .ddp-btn-line-s {
    position: absolute;
    top: 0;
    right: 0;
    padding: 6px 0 7px 0;
    font-size: 12px;
    width: 48px;
  }
}

.ddp-form-error .ddp-data-error {
  color: #e70000;
  font-size: 12px;
  font-style: italic;

  &:before {
    display: inline-block;
    width: 13px;
    height: 13px;
    background: url(/assets/bi/images/icon_info.png) no-repeat;
    background-position: -28px top;
    vertical-align: middle;
    content: '';
  }
}

/**************************************************************
    edit
**************************************************************/
/* wrap edit */

.ddp-wrap-edit {
  .ddp-label-type {
    float: left;
    padding-right: 24px;
    color: #90969f;
    font-size: 13px;
    line-height: 30px;

    + .ddp-ui-edit-option.ddp-inline {
      position: relative;
      top: 8px;

      &.type-tag {
        top: 6px;
      }
    }
  }

  .ddp-ui-edit-option {
    float: left;

    .ddp-data-txt {
      line-height: 30px;
      color: #4b515b;
      font-size: 13px;

      .ddp-bar {
        color: #90969f;
      }
    }
  }

  .ddp-ui-calen {
    display: inline-block;
    vertical-align: middle;
  }
}

/* wrap edit2 */

.ddp-wrap-edit2 {
  position: relative;
  margin-top: 35px;

  &.ddp-type + .ddp-wrap-edit2.ddp-type {
    margin-top: 20px;
  }

  label.ddp-label-type {
    display: block;
    padding-bottom: 13px;
    color: #4c515a;
    font-size: 14px;

    &.ddp-bold {
      font-weight: bold;
    }

    &.ddp-normal {
      font-weight: normal;
      margin-top: 0;
    }

    .ddp-sub {
      font-size: 14px;
      color: #90969f;
      font-weight: 300;
    }
  }

  .ddp-ui-option-sub {
    padding: 0 20px;

    .ddp-ui-edit-option .ddp-label-radio {
      margin-right: 100px;
      vertical-align: top;
    }

    + .ddp-ui-option-sub {
      margin-top: 20px;
    }
  }

  .ddp-wrap-hover-info {
    display: inline-block;
    margin-left: 2px;
    position: relative;
    vertical-align: middle;
    cursor: pointer;

    &:before {
      display: inline-block;
      position: absolute;
      top: -10px;
      left: -10px;
      right: 0;
      bottom: -10px;
      width: 30px;
      content: '';
    }

    &:hover {
      z-index: 50;
    }

    .ddp-icon-info3 {
      display: inline-block;
      position: relative;
      top: -1px;
      width: 11px;
      height: 11px;
      background: url(/assets/bi/images/icon_que.png) no-repeat;
      background-position: left -12px;
    }

    .ddp-box-layout4 {
      display: none;
      position: absolute;
      top: -15px;
      left: 20px;
      width: 320px;
    }

    &:hover .ddp-box-layout4 {
      display: block;
    }

    .ddp-box-layout4.ddp-config {
      margin: 0;
      padding: 0;
      height: auto;
    }
  }
}

.ddp-wrap-edit3 .ddp-wrap-hover-info .ddp-box-layout4.ddp-config {
  margin: 0;
  padding: 0;
  height: auto;
}

.ddp-wrap-edit2 {
  .ddp-ui-option-sub {
    .ddp-ui-label-name {
      display: block;
      padding-bottom: 10px;
      color: #4c515a;
      font-size: 13px;

      .ddp-wrap-hover-info {
        position: relative;
        top: 2px;
        vertical-align: middle;
      }
    }

    .ddp-radio-set {
      float: left;
    }

    .ddp-radio-hidden {
      overflow: hidden;

      .ddp-ui-calen {
        width: 320px;
        padding: 10px 0 0 16px;
      }
    }
  }

  .ddp-check {
    position: relative;
    padding-top: 4px;

    &.ddp-disabled {
      opacity: 0.5;

      &:after {
        display: inline-block;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        content: '';
      }
    }
  }

  .ddp-box-datanone {
    padding: 20px 0;
    border-radius: 2px;
    background-color: #fafafb;
    text-align: center;
    color: #b7bac1;
    font-size: 13px;
  }

  .ddp-error {
    display: block;
    color: #eb5f58;
    font-size: 12px;
  }

  .ddp-inline label.ddp-label-radio {
    display: inline-block;
    float: left;
    margin-left: 60px;
    box-sizing: border-box;

    &:first-of-type {
      margin-left: 0;
    }
  }

  &.ddp-mgt0 {
    margin-top: 0;
  }

  &.ddp-inline {
    float: left;
    width: 100%;

    .ddp-label-type {
      float: left;
      width: 117px;
    }

    .ddp-inline {
      display: block;
    }
  }

  .ddp-ui-error {
    display: block;
    visibility: hidden;
    position: absolute;
    bottom: -20px;
    left: 0;
    right: 0;
    color: #dc494f;
    font-size: 12px;
  }

  &.ddp-error {
    .ddp-ui-error {
      visibility: inherit;
    }

    .ddp-input-type {
      border-bottom: 1px solid #ff2a1b;
    }
  }

  &[class*='ddp-col-'] .ddp-ui-error {
    left: 8px;
  }

  .ddp-radio-inline {
    display: inline-block;

    li {
      display: inline-block;
      padding-right: 30px;

      .ddp-label-radio {
        display: inline-block;
        position: relative;
      }

      .ddp-type-selectbox {
        display: inline-block;
        min-width: 160px;
        margin-left: 5px;
        vertical-align: middle;

        .ddp-list-selectbox {
          right: initial;
          min-width: 100%;
          white-space: nowrap;
        }
      }

      .ddp-list-selectbox li {
        display: block;
        padding-right: 0;
      }
    }
  }

  .ddp-list-checktype li {
    padding: 5px 0;

    .ddp-txt-det {
      color: #808dcd;
      font-size: 12px;
      font-style: italic;
      vertical-align: top;
    }
  }
}

/* form label2 */

.ddp-form-label2 {
  label.ddp-label-type {
    display: inline-block;
    padding-right: 15px;
    color: #90969f;
    font-size: 14px;
    vertical-align: middle;
  }

  .ddp-wrap-dropdown {
    display: inline-block;
    vertical-align: middle;
  }
}

/* wrap edit3 */

.ddp-wrap-edit3 {
  display: table;
  width: 100%;
  table-layout: fixed;
  min-height: 30px;

  &.type-sub {
    padding-left: 20px;
  }

  &.ddp-error .ddp-ui-error {
    display: inline-block;
  }

  .ddp-ui-error {
    display: none;
    position: relative;
    top: -2px;
    margin-left: 4px;
    color: #dc494f;
    font-size: 12px;
    white-space: nowrap;
    font-style: italic;
  }

  .ddp-ui-label-name {
    display: block;
    width: 100%;
    margin-bottom: 14px;
    color: #b7b9c2;
    font-size: 13px;
    vertical-align: middle;
  }

  label.ddp-label-type {
    display: table-cell;
    width: 131px;
    padding: 7px 0;
    color: #b7b9c2;
    font-size: 13px;
    vertical-align: top;

    &.ddp-text-right {
      padding-right: 15px;
    }

    &.type-color {
      color: #90969f;
    }

    .ddp-type-necessary {
      color: $primary;
    }
  }

  &.ddp-type .ddp-label-type {
    position: relative;
    padding-right: 10px;
    color: #4c515a;
    font-size: 14px;
    box-sizing: border-box;

    &.ddp-size {
      width: 180px;
      font-size: 14px;
    }

    &.ddp-bold {
      font-weight: bold;
      font-size: 13px;
    }

    &.ddp-normal {
      padding-top: 0;
      width: 170px;
      font-weight: normal;
    }
  }

  .ddp-ui-sub-edit {
    margin-top: 4px;
  }

  label.ddp-label-subtype {
    width: 121px;
    float: left;
    padding-left: 20px;
    color: #4c515a;
    font-size: 14px;
    box-sizing: border-box;
    vertical-align: middle;
    line-height: 30px;
  }

  .ddp-wrap-hover-info {
    display: inline-block;
    margin-left: 2px;
    position: relative;
    vertical-align: middle;
    cursor: pointer;

    &:hover {
      z-index: 50;
    }

    .ddp-icon-info3 {
      display: inline-block;
      position: relative;
      top: -1px;
      width: 11px;
      height: 11px;
      background: url(/assets/bi/images/icon_que.png) no-repeat;
      background-position: left -12px;
    }

    .ddp-box-layout4 {
      display: none;
      position: absolute;
      top: -15px;
      left: 20px;
      width: 320px;
    }

    &:hover .ddp-box-layout4 {
      display: block;
    }
  }

  .ddp-ui-edit-option .ddp-apply.type-catalog .ddp-type-search {
    position: relative;
  }
}

.ddp-apply.type-catalog {
  .ddp-type-search {
    &.ddp-selected .ddp-box-layout4 {
      display: block;
    }

    .ddp-box-layout4 {
      display: none;
      position: absolute;
      top: 100%;
      left: 0;
      right: 0;
      margin-top: 2px;
      width: auto;

      .ddp-data-title + ul.ddp-wrap-catalogs2 {
        margin-top: -6px;
      }

      ul.ddp-wrap-catalogs2 {
        &.ddp-scroll {
        }

        margin: 9px 0 9px 0;
        padding: 0;

        li a .ddp-ui-catalogs2 {
          padding: 0 0 0 20px;
        }
      }

      &.type-button {
        padding-bottom: 40px;
      }

      .ddp-btn-bottom {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        padding: 9px 0;
        border-top: 1px solid #e7e7ea;
        text-align: center;
        background-color: #fff;
      }
    }
  }

  .ddp-box-layout4 .ddp-data-title {
    color: #b5b9c2;
    font-size: 12px;
    padding: 15px 14px 13px 14px;
  }
}

.ddp-wrap-edit3 {
  .ddp-ui-edit-option .ddp-apply {
    .ddp-type-search {
      float: left;
    }

    .ddp-btn-add {
      display: inline-block;
      padding: 7px 14px;
      margin-left: 4px;
      border-radius: 2px;
      color: #fff;
      font-size: 13px;
      background-color: #b7b9c2;

      .ddp-icon-plus {
        display: inline-block;
        margin-right: 6px;
      }

      &:hover {
        background-color: #90969f;
      }
    }
  }

  .ddp-ui-edit-suboption {
    overflow: hidden;
  }

  .ddp-ui-edit-option {
    .ddp-ui-option-in {
      .ddp-input-typebasic {
      }

      .ddp-box-multy .ddp-input-typebasic {
        margin-top: 0;
      }
    }

    .ddp-wrap-list-detail {
      padding: 6px 0 8px 0;
    }

    .ddp-list-detail + .ddp-list-detail {
      display: block;
      padding-top: 7px;
    }

    display: table-cell;
    position: relative;
    min-height: 30px;
    vertical-align: middle;
    box-sizing: border-box;

    .ddp-input-apply {
      display: inline-block;
      width: 200px;
    }

    span.ddp-detail {
      display: inline-block;
      padding: 7px 0;
      font-size: 13px;

      em.ddp-by {
        color: #b7bac1;
      }
    }

    .ddp-ui-buttons {
      margin-top: 8px;
    }

    .ddp-input-typebasic.ddp-input-size {
      display: inline-block;
      width: 170px;
    }

    .ddp-ui-option-in {
      display: inline-block;
      width: 100%;
    }

    .ddp-label-radio {
      display: inline-block;
      position: relative;
      margin-right: 20px;
      vertical-align: top;

      &:last-of-type {
        margin-right: 0;
      }
    }

    &.ddp-middle {
      .ddp-label-radio {
        vertical-align: middle;
      }

      .ddp-wrap-radio {
        float: left;
      }
    }

    .ddp-ui-option-sub {
      position: relative;
      top: 5px;
      margin-top: 20px;

      &:first-of-type {
        margin-top: 0;
      }

      label.ddp-ui-label-name {
        margin-bottom: 8px;
        color: #4c515a;
        font-size: 13px;
        font-weight: normal;
      }
    }

    &.ddp-type {
      position: relative;
      padding-bottom: 20px;

      &.ddp-btn-multy {
        padding-right: 107px;

        .ddp-btn-gray {
          position: absolute;
          top: 0;
          right: 0;
          width: 100px;
          padding: 7px;
          text-align: center;
          box-sizing: border-box;
        }
      }
    }

    .ddp-data-error {
      position: absolute;
      bottom: 4px;
      left: 0;
      color: #e70000;
      font-size: 12px;
      font-style: italic;

      &:before {
        display: inline-block;
        margin-right: 2px;
        width: 13px;
        height: 13px;
        vertical-align: middle;
        background: url(/assets/bi/images/icon_info.png) no-repeat;
        background-position: -28px top;
        content: '';
      }
    }

    .ddp-data-info {
      position: absolute;
      bottom: 4px;
      left: 0;
      color: #b6b9c2;
      font-size: 12px;

      &:before {
        display: inline-block;
        margin-right: 2px;
        width: 13px;
        height: 13px;
        vertical-align: middle;
        background: url(/assets/bi/images/icon_info.png) no-repeat;
        background-position: left -16px;
        content: '';
      }
    }

    &.ddp-error .ddp-data-error {
      bottom: -15px;
    }

    .ddp-box-slider {
      padding: 6px 0 0 0;

      .ddp-checkbox-slide.ddp-checkbox-automatic2 {
        padding-left: 30px;
        margin-bottom: 10px;
      }
    }

    &.ddp-type .ddp-type-multy {
      margin-bottom: -20px;

      [class*='ddp-col-'] {
        position: relative;
        padding-bottom: 20px;
      }
    }

    .ddp-type-form {
      position: relative;

      &.ddp-disabled {
        cursor: no-drop;

        &:before {
          display: inline-block;
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          content: '';
          background-color: rgba(255, 255, 255, 0.3);
          z-index: 1;
        }
      }

      .ddp-type-selectbox.ddp-flow {
        float: left;
      }

      .ddp-btn-pop {
        float: left;
        margin-left: 4px;
      }
    }

    .type-form-multi {
      .ddp-label-checkbox.type-inline {
        position: relative;
        top: 5px;
        margin-left: 10px;
      }

      + .ddp-wrap-label.type-label {
        margin-top: 6px;
      }
    }
  }

  &.ddp-type .ddp-type-multy {
    .ddp-type-selectbox {
      width: 100%;
    }

    margin: 0 -3px;

    [class*='ddp-col-'] {
      position: relative;
      padding: 0 3px;
    }
  }

  .ddp-ui-edit-option .ddp-textarea {
    display: block;
    padding: 5px 10px;
    width: 100%;
    border: 1px solid #d0d1d9;
    border-radius: 2px;
    box-sizing: border-box;
  }

  &.ddp-type {
    .ddp-type-selectbox {
      &.ddp-flow {
        width: 289px;
      }

      display: inline-block;
      width: 168px;
      box-sizing: border-box;
    }

    &.ddp-block .ddp-type-selectbox {
      width: 100%;
      box-sizing: border-box;
    }
  }

  .ddp-form-edit {
    position: relative;
    padding-left: 80px;

    .ddp-label-sub {
      position: absolute;
      top: 8px;
      left: 0;
      width: 80px;
      padding-right: 6px;
      text-align: right;
      color: #90969f;
      font-size: 13px;
      box-sizing: border-box;
    }
  }
}

.ddp-table-detail.ddp-setting .ddp-wrap-edit3 label.ddp-label-type {
  width: 195px;
}

.ddp-box-detail.ddp-label-size .ddp-wrap-edit3 label.ddp-label-type {
  width: 150px;
}

.ddp-wrap-edit4 {
  .ddp-label-type {
    padding-bottom: 8px;
    font-size: 13px;
    color: #4b515b;
  }

  .ddp-form-edit.type-file {
    white-space: nowrap;

    input {
      display: inline-block;
      float: left;
      width: 460px;
      margin-right: 4px;
    }

    .ddp-btn-pop {
      float: left;
    }

    .ddp-form-file {
      display: inline-block;
    }

    .ddp-ui-message {
      display: inline-block;
      margin-left: 10px;
      white-space: nowrap;
      vertical-align: middle;

      [class*='ddp-data-'] {
        display: block;
        position: relative;
        top: 6px;
        padding-top: 0;
      }
    }
  }
}

/**************************************************************
    popup edit
**************************************************************/

.ddp-pop-select.ddp-selected .ddp-pop-fix {
  display: block !important;
}

.ddp-box-popup3 .ddp-wrap-edit3 {
  &.ddp-type .ddp-type-selectbox {
    display: block;
  }

  &.ddp-error .ddp-ui-error {
    margin-left: 0;
    position: absolute;
    top: inherit;
    bottom: -20px;
  }
}

/**************************************************************
    link detail
**************************************************************/

.ddp-wrap-link-detail2 {
  display: inline-block;
  position: relative;

  a.ddp-link-info {
    margin-left: 3px;
    color: #4b515b;
    font-size: 13px;
    font-weight: bold;
  }

  &:after {
    display: inline-block;
    content: ',';
  }

  &:last-of-type:after {
    display: none;
  }

  a.ddp-link-info {
    &:hover {
      text-decoration: underline;
    }

    &.ddp-type {
      display: inline-block;
      position: relative;

      .ddp-icon-window {
        display: none;
        position: absolute;
        top: 2px;
        right: 0;
        width: 12px;
        height: 10px;
        background: url(/assets/bi/images/icon_window.png) no-repeat;
      }

      &:hover .ddp-icon-window {
        display: block;
      }
    }
  }

  .ddp-box-layout4 {
    display: none;
    position: absolute;
    top: 100%;
    right: 0;

    &.ddp-left {
      right: inherit;
      left: 0;
    }
  }

  &:hover .ddp-box-layout4 {
    display: block;
  }
}

/**************************************************************
    메세지
**************************************************************/

.ddp-ui-buttons.ddp-inline .ddp-ui-message {
  display: inline-block;
  margin-left: 10px;

  .ddp-data-error2 {
    padding-top: 0;
  }
}

.ddp-ui-message {
  span {
    &.ddp-data-ok,
    &.ddp-data-error {
      position: relative;
      margin-top: 10px;
      padding-left: 17px;
      color: $primary;
      font-size: 12px;
      font-style: italic;
    }

    &.ddp-data-error2 {
      display: block;
      padding: 10px 0 0 0;
      color: #ca4b4b;
      font-style: italic;
    }

    &.ddp-data-error {
      color: #ca4b4b;
    }

    &.ddp-data-ok em.ddp-icon-ok,
    &.ddp-data-error em.ddp-icon-error {
      display: inline-block;
      position: absolute;
      top: 3px;
      left: 0;
      background: url(/assets/bi/images/icon_message.png) no-repeat;
    }

    &.ddp-data-ok em.ddp-icon-ok {
      width: 11px;
      height: 8px;
      background-position: left -10px;
    }

    &.ddp-data-error em.ddp-icon-error {
      width: 9px;
      height: 9px;
    }
  }

  &.ddp-type {
    margin-top: -6px;
  }
}

/**************************************************************
    foling
**************************************************************/

.ddp-ui-folding {
  margin: 30px 0 0;

  .ddp-fold-label {
    display: inline-block;
    margin-bottom: 12px;
    color: #4c515a;
    font-size: 13px;
    cursor: pointer;
    text-decoration: underline;
  }

  .ddp-icon-view {
    display: inline-block;
    position: relative;

    &:before {
      display: inline-block;
      margin-left: 5px;
      width: 7px;
      height: 4px;
      background: url('/assets/bi/images/icon_select.png') no-repeat;
      background-position: -24px top;
      content: '';
    }
  }

  &.ddp-selected .ddp-icon-view:before {
    transform: rotate(180deg);
  }

  .ddp-ui-option-in {
    padding: 11px 27px 0 0;

    .ddp-type-label {
      vertical-align: top;
      position: relative;
      font-weight: bold;
      font-size: 14px;
    }

    .ddp-box-option-input input {
      &[type='text'],
      &[type='number'] {
        padding: 7px 5px 6px 5px;
      }
    }
  }

  .ddp-ui-sub-folding {
    display: none;
  }

  &.ddp-selected .ddp-ui-sub-folding {
    display: block;
  }
}

/**************************************************************
    setting
**************************************************************/

.ddp-edit-setting {
  margin-top: 40px;

  .ddp-label-name {
    display: block;
    padding: 9px 0;
    margin-right: 8px;
    color: #4c515a;
    font-size: 14px;
    font-weight: bold;
    cursor: pointer;

    .ddp-icon-drop {
      display: inline-block;
      width: 13px;
      height: 8px;
      margin-left: 10px;
      background: url(/assets/bi/images/icon_arrow.png) no-repeat;
      vertical-align: middle;
      transform: rotate(180deg);
    }

    &:hover {
      background-color: #f6f6f7;
    }
  }

  &.ddp-selected .ddp-label-name {
    background-color: #f6f6f7;

    .ddp-icon-drop {
      transform: rotate(0deg);
    }
  }

  .ddp-label-sub {
    display: block;
    position: absolute;
    top: 20px;
    left: 0;
    width: 172px;
    padding: 0 20px 5px 20px;
    line-height: 16px;
    color: #4c515a;
    font-size: 13px;
    word-break: break-word;
    box-sizing: border-box;
  }

  .ddp-clear.ddp-list-code {
    margin-bottom: 2px;
  }

  .ddp-col-6 {
    padding: 0 6px;
  }

  textarea {
    display: block;
    width: 100%;
    border: 1px solid #d0d1d9;
    overflow: hidden;
  }

  .ddp-wrap-down {
    display: none;
    padding-left: 172px;
  }

  &.ddp-selected .ddp-wrap-down {
    display: block;
    padding-left: 0;

    .ddp-wrap-edit2:first-of-type {
      margin-top: 0;
    }
  }

  .ddp-edit-sub {
    position: relative;
    padding: 20px 0 0 172px;

    .ddp-ui-message {
      margin-top: 6px;

      .ddp-data-ok,
      .ddp-data-error {
        margin-left: 12px;
      }
    }
  }
}

.ddp-ui-dbconnect .ddp-edit-setting.ddp-type:first-of-type {
  padding: 0;
  margin: 0;
  border-top: none;
}

.ddp-edit-setting {
  &.ddp-type {
    margin-top: 30px;
    padding-top: 30px;
    border-top: 1px dotted #ccc;
  }

  .ddp-fold-link {
    display: inline-block;
    padding-bottom: 20px;
    color: #4c515a;
    font-size: 13px;
    text-decoration: underline;

    &:after {
      display: inline-block;
      width: 7px;
      height: 4px;
      margin-left: 10px;
      background: url('/assets/bi/images/icon_select.png') no-repeat;
      background-position: -16px top;
      vertical-align: middle;
      content: '';
    }
  }

  &.ddp-selected .ddp-fold-link:after {
    transform: rotate(180deg);
  }
}

.ddp-detail-setting {
  padding: 35px 0 0 0;

  .ddp-fold-link {
    display: inline-block;
    padding-bottom: 20px;
    color: #4c515a;
    font-size: 13px;
    text-decoration: underline;
  }

  .ddp-wrap-down {
    display: none;
  }

  .ddp-fold-link:after {
    display: inline-block;
    width: 7px;
    height: 4px;
    margin-left: 10px;
    background: url('/assets/bi/images/icon_select.png') no-repeat;
    background-position: -16px top;
    vertical-align: middle;
    content: '';
  }

  &.ddp-selected {
    .ddp-fold-link:after {
      transform: rotate(180deg);
    }

    .ddp-wrap-down {
      display: block;
    }
  }
}

/**************************************************************
    no data
**************************************************************/

.ddp-ui-empty {
  padding: 20px;
  color: #b7b9c3;
  font-size: 12px;
}

.ddp-data-empty {
  display: table;
  width: 100%;
  height: 100%;
  text-align: center;

  .ddp-data-contents {
    display: table-cell;
    text-align: center;
    color: #b7b9c3;
    font-size: 12px;
    vertical-align: middle;
  }
}

ul.ddp-list-value li.ddp-data-none {
  background: none;
  color: #b7b9c3;
}

.ddp-ui-import-option .ddp-ui-grid .ddp-search-none {
  padding: 20px;
  font-size: 12px;
  color: #ca4b4b;
}

/**************************************************************
	INPUT NUMBER
**************************************************************/

.ddp-input-number {
  position: relative;
  border-radius: 2px;
  border: 1px solid #d0d1d8;
  background: #fff;
  overflow: hidden;

  input[type='number'] {
    display: block;
    width: 100%;
    padding: 6px 58px 7px 10px;
    color: #4b515b;
    font-size: 12px;
    border: none;
    box-sizing: border-box;
  }

  .ddp-input-number-nav {
    position: absolute;
    top: 0;
    right: 0;
  }
}

.ddp-input-number-button {
  position: relative;
  width: 28px;
  height: 30px;
  float: left;
  border-left: 1px solid #d0d1d9;
  background-color: #f7f7f8;
  cursor: pointer;

  &:before {
    display: inline-block;
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    background: url(/assets/bi/images/btn_minus_plus.png) no-repeat;
  }

  &.ddp-input-number-up:before {
    width: 9px;
    height: 9px;
    margin: -5px 0 0 -5px;
    background-position: left -2px;
  }

  &.ddp-input-number-down:before {
    width: 9px;
    height: 1px;
    margin: 0 0 0 -5px;
  }

  &.ddp-disabled {
    opacity: 0.5;
    cursor: no-drop;
  }
}

.ddp-input-number.ddp-disabled:before {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.5);
  cursor: no-drop;
  z-index: 1;
  content: '';
}

/**************************************************************
    에러 메세지
**************************************************************/

.ddp-wrap-datainput {
  position: relative;

  .ddp-box-error {
    display: none;
    position: absolute;
    bottom: 34px;
    left: 0;
    right: 0;
    padding: 10px;
    border: 1px solid #da5359;
    border-radius: 2px;
    background-color: #fff;
    color: #000;
    font-size: 12px;
    white-space: normal;
    box-sizing: border-box;

    &.ddp-selected {
      display: block;
    }

    .ddp-txt-error {
      display: block;
      margin-bottom: 10px;
    }

    .ddp-icon-close {
      display: inline-block;
      position: absolute;
      top: 8px;
      right: 7px;
      width: 11px;
      height: 11px;
      background: url(/assets/bi/images/btn_close.png) no-repeat;
      background-position: left -58px;
    }
  }
}

.ddp-txt-error {
  display: inline-block;
  color: #dc494f;
  font-size: 12px;
  font-style: italic;

  &.type-font-normal {
    font-style: normal;
  }

  &.ddp-size {
    font-size: 12px;
    color: #eb5f58;
  }

  em {
    &.ddp-icon-error {
      display: inline-block;
      width: 15px;
      height: 15px;
      margin-right: 6px;
      background: url(/assets/bi/images/icon_error.png) no-repeat;
      vertical-align: middle;
    }

    &.ddp-icon-error-s {
      position: relative;
      top: -1px;
      width: 13px;
      height: 13px;
      margin-right: 4px;
      background: url(/assets/bi/images/icon_info.png) no-repeat;
      background-position: -28px 0;
      vertical-align: middle;
    }
  }
}

.ddp-txt-error2 {
  color: #ff3324;
  text-align: center;
  font-size: 13px;
}

.ddp-txt-error.ddp-default {
  color: #b7b9c2;

  .ddp-icon-error-s {
    display: inline-block;
    width: 13px;
    height: 13px;
    background: url(/assets/bi/images/icon_info.png) no-repeat;
    background-position: left -16px;
    vertical-align: middle;
  }
}

.ddp-txt-info {
  position: relative;
  color: #b5b8c2;
  font-size: 12px;

  .ddp-icon-info {
    display: inline-block;
    width: 14px;
    height: 14px;
    margin-right: 6px;
    background: url(/assets/bi/images/icon_info3.png) no-repeat;
    background-position: -15px -94px;
    vertical-align: middle;
  }

  &.type-error {
    .ddp-icon-error {
      display: inline-block;
      width: 14px;
      height: 14px;
      margin-right: 6px;
      background: url(/assets/bi/images/icon_info3.png) no-repeat;
      background-position: -15px -94px;
      vertical-align: middle;
      position: relative;
      top: -1px;
      background: url(/assets/bi/images/icon_info3.png) no-repeat;
      background-position: 0 -109px;
    }

    color: #dd4950;
  }
}

/**************************************************************
    popup form
**************************************************************/

.ddp-box-layout4 {
  position: relative;
  width: 211px;
  border-radius: 2px;
  border: 1px solid #ddd;
  background-color: #fff;
  z-index: 1;
  box-sizing: border-box;
  -moz-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);

  /* drop shadow */
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);

  /* drop shadow */
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);

  /* drop shadow */

  .ddp-data-title {
    padding: 15px 14px 15px 14px;
    color: #4b515b;
    font-size: 12px;
    font-weight: bold;

    &.type-long {
      display: block;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  .ddp-data-det {
    padding: 18px 20px;
    color: #90969f;
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    word-break: normal;
    white-space: normal;

    .ddp-link-det {
      margin: 15px 0 0 0;
      position: relative;

      a:hover {
        text-decoration: underline;
      }

      .ddp-ui-tooltip-info {
        display: none;
        max-width: 300px;
        white-space: normal;
      }

      a:hover .ddp-ui-tooltip-info {
        display: block;
        position: absolute;
        left: -3px;
        top: 100%;
        right: initial;

        em.ddp-icon-view-top {
          left: 30px;
        }
      }
    }
  }

  .ddp-data-title + .ddp-data-det {
    padding-top: 0;
  }

  .ddp-data-det {
    &.ddp-thin {
      font-weight: 300 !important;
    }

    > ul {
      padding: 25px 0 0 0;
    }
  }

  table {
    tr td {
      word-break: break-all;
    }

    &.ddp-table-code {
      table-layout: fixed;
      border: none;
      background: none;
    }
  }

  .ddp-label-table {
    padding: 0 15px;
    margin-bottom: -4px;
    max-width: 520px;

    .ddp-txt-label {
      display: inline-block;
      position: relative;
      padding-left: 6px;
      color: #4b515b;
      font-size: 12px;

      &:before {
        position: absolute;
        top: 3px;
        left: 0;
        height: 9px;
        border-left: 2px solid #4b515b;
        content: '';
      }
    }
  }

  &.ddp-config {
    width: 350px !important;
  }

  .ddp-data-code {
    padding: 14px 15px 20px 15px;
    max-height: 250px;
    overflow: auto;
    box-sizing: border-box;
  }

  .ddp-data-title + .ddp-data-code {
    padding-top: 0;
    white-space: normal;
    padding-top: 0;
  }

  .ddp-table-code {
    width: 100%;
  }

  .ddp-data-code {
    &.ddp-size {
      max-height: initial;
      overflow: inherit;
    }

    .ddp-link-detail {
      display: inline-block;
      padding: 20px 0 0 0;
      text-decoration: underline;
      font-style: italic;
      color: #4b515b;
      font-size: 12px;

      &:hover {
        font-weight: bold;
      }
    }

    tr td {
      border: none !important;
    }
  }
}

/* table code */

table.ddp-table-form.ddp-inherit .ddp-box-layout4 .ddp-table-code tr td {
  position: relative;
  padding: 2px 0;
  cursor: default;
  background: #fff !important;
}

.ddp-box-layout4 table.ddp-table-code tr {
  td {
    position: relative;
    padding: 2px 0;
    cursor: default;
    background: #fff !important;

    .ddp-txt-long {
      display: block;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      word-wrap: normal;

      &:hover + table.ddp-table-form.ddp-inherit .ddp-box-layout4 .ddp-table-code tr td {
        border: none !important;
      }
    }
  }

  &:hover td,
  td:hover {
    background: #fff !important;
  }
}

.ddp-ui-preview-contents table.ddp-table-form.ddp-table-details tbody tr td table.ddp-table-code tr td:hover {
  background: #fff !important;
}

.ddp-box-layout4 {
  .ddp-wrap-data-detail {
    padding: 15px;

    .ddp-ui-top-title {
      padding-bottom: 15px;
      color: #4b515b;
      font-weight: bold;
      font-size: 12px;
    }
  }

  table.ddp-table-pop {
    width: 100%;
    table-layout: fixed;

    tr {
      th {
        padding: 3px 0;
        text-align: left;
        color: #b7bac1;
        font-weight: normal;
        font-size: 12px;
        vertical-align: top;
      }

      td {
        padding: 3px 0;
        color: #4b515b;
        font-size: 12px;
        word-break: break-all;
      }
    }
  }

  .ddp-link-view {
    display: inline-block;
    margin-top: 20px;
    color: $primary;
    font-weight: 300;
    font-size: 12px;
  }

  table.ddp-table-pop tr td a {
    color: #4b515b;
    font-size: 12px;

    &:hover {
      text-decoration: underline;
    }
  }

  .ddp-link-view {
    em.ddp-icon-view {
      display: inline-block;
      width: 13px;
      height: 11px;
      margin: 0 5px 0 0;
      background: url(/assets/bi/images/icon_selection.png) no-repeat;
      background-position: left -44px;
    }

    &:hover {
      text-decoration: underline;
    }
  }
}

.ddp-pop-fix {
  display: none !important;
  position: fixed !important;
  right: inherit !important;
}

/**************************************************************
    날짜 form
**************************************************************/

.ddp-wrap-dateinfo {
  margin-top: 14px;

  &:first-of-type,
  .ddp-ui-dateinfo:first-of-type {
    margin-top: 0;
  }
}

.ddp-ui-dateinfo {
  position: relative;
  padding-left: 38px;
  margin-top: 4px;

  .ddp-box-radius {
    border-radius: 5px;
  }

  .ddp-box-dateinfo {
    position: relative;
    border: 1px solid #d0d1d8;
    background-color: #fff;

    &.ddp-disabled {
      background-color: rgba(240, 240, 240, 0.3);
      opacity: 0.7;
      cursor: no-drop;
    }

    .ddp-disabled {
      position: absolute;
      top: -1px;
      left: -1px;
      right: -1px;
      bottom: -1px;
      background-color: #fff;
      border: 1px solid rgba(208, 209, 216, 0.7);

      span {
        display: block;
        padding: 5px 5px 6px 5px;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(240, 240, 240, 0.3);
        opacity: 0.7;
      }
    }

    input {
      display: inline-block;
      float: left;
      padding: 8px 5px 6px 5px;
      border: none;
      color: #4b515b;
      font-size: 12px;
      box-sizing: border-box;

      &.ddp-input-calen {
        width: 49%;
        background: none;
      }

      &.ddp-input-time {
        width: 51%;
        background: none;
      }

      &.ddp-full {
        width: 100% !important;
      }
    }

    .ddp-box-date {
      position: relative;
      float: Left;
      width: 55%;
      padding-left: 28px;
      box-sizing: border-box;

      + .ddp-box-date {
        width: 45%;
        border-left: 1px solid #d0d1d8;
      }

      &:first-of-type {
        border-left: none;
      }

      em {
        &.ddp-icon-calen {
          display: inline-block;
          position: absolute;
          top: 50%;
          left: 9px;
          margin-top: -7px;
          width: 13px;
          height: 14px;
          background: url(/assets/bi/images/icon_calen.png) no-repeat;
        }

        &.ddp-icon-time {
          position: absolute;
          top: 50%;
          left: 9px;
          margin-top: -7px;
        }
      }

      .ddp-txt-calen {
        display: block;
        width: 100% !important;
        padding-left: 0;
        padding-right: 10px;
        line-height: 29px;
        height: 28px;
        font-size: 13px;
        overflow: hidden;
        box-sizing: border-box;
        cursor: pointer;
      }

      .ddp-txt-time {
        display: block;
        width: 100% !important;
        padding-left: 0;
        padding-right: 10px;
        line-height: 29px;
        height: 28px;
        font-size: 13px;
        overflow: hidden;
        box-sizing: border-box;
        cursor: pointer;
        width: 72px;
      }

      &.ddp-full {
        width: 100%;

        .ddp-txt-calen {
          width: 100%;
        }
      }
    }
  }

  .ddp-txt-date {
    position: absolute;
    top: 6px;
    left: 0;
    color: #90969f;
    font-size: 12px;
  }

  &.ddp-to .ddp-txt-date {
    left: 15px;
  }

  .ddp-messege {
    padding: 6px 10px;
    border: 1px solid #d0d1d8;
    font-size: 13px;
    text-align: left;
  }
}

.ddp-wrap-dateinfo {
  &.ddp-disabled .ddp-ui-dateinfo:before {
    display: inline-block;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.6);
    opacity: 0.5;
    cursor: no-drop;
    z-index: 1;
  }

  &.ddp-readonly .ddp-box-dateinfo {
    border: none;
    background: none;
  }
}

.ddp-form-date {
  &.ddp-inline {
    margin: 0 -5px;
    overflow: hidden;

    .ddp-day {
      float: left;
      width: 50%;
      padding: 0 5px;
      box-sizing: border-box;
    }
  }

  .ddp-day + .ddp-day {
    margin-top: 4px;
  }

  &.ddp-inline .ddp-day + .ddp-day {
    margin-top: 0;
  }

  .ddp-day .ddp-label-date {
    float: left;
    position: relative;
    top: 6px;
    padding-right: 4px;
    width: 35px;
    color: #90969f;
    font-size: 12px;
    text-align: right;
    box-sizing: border-box;
  }

  .ddp-box-date {
    position: relative;
    height: 30px;
    border: 1px solid #d0d1d8;
    box-sizing: border-box;
    overflow: hidden;
    cursor: pointer;

    .ddp-icon-calen {
      float: right;
    }

    .ddp-wrap-input {
      position: relative;
      padding-left: 23px;
      height: 100%;
      overflow: hidden;
      background-color: #fff;

      &.icon-none {
        padding-left: 0;
      }

      .ddp-icon-calen {
        display: inline-block;
        position: absolute;
        top: 50%;
        left: 9px;
        margin-top: -7px;
        width: 13px;
        height: 14px;
        background: url(/assets/bi/images/icon_calen.png) no-repeat;
      }

      input {
        display: block;
        padding: 0 5px 0 5px;
        width: 100%;
        height: 100%;
        border: none;
        font-size: 13px;
        cursor: pointer;
        box-sizing: border-box;
      }
    }

    .ddp-icon-apply {
      display: none;
      position: relative;
      float: right;
      margin: 2px;
      width: 24px;
      height: 24px;
      background-color: #d0d1d8;

      &:before {
        display: inline-block;
        position: absolute;
        top: 50%;
        left: 50%;
        margin: -4px 0 0 -6px;
        width: 11px;
        height: 8px;
        background: url(/assets/bi/images/icon_select2.png) no-repeat;
        background-position: -27px top;
        content: '';
      }
    }

    &:hover,
    &.ddp-focus,
    &.ddp-edit {
      border: 1px solid #b7b9c2;
    }

    &.ddp-focus .ddp-icon-apply {
      display: block;
    }

    &.ddp-edit .ddp-icon-apply {
      display: block;
      background-color: #9ca2cc;
    }

    &.ddp-disabled {
      border: 1px solid #d0d1d8;

      .ddp-txt-disabled {
        display: block;
        position: absolute;
        top: -1px;
        left: -1px;
        right: -1px;
        bottom: -1px;
        border: 1px solid rgba(208, 209, 216, 0.3);
        background: #fff;
        z-index: 10;
        cursor: no-drop;

        span {
          display: block;
          padding: 5px 5px 6px 5px;
          background-color: rgba(240, 240, 240, 0.3);
          opacity: 0.7;
        }
      }
    }
  }
}

.ddp-filter-time {
  &.ddp-clear {
    margin: 0 -3px;
  }

  [class*='ddp-col'] {
    padding: 0 3px;
  }

  .ddp-box-multy {
    input.ddp-input-typebasic {
      float: left;
      width: 45%;
      border: none;
      box-sizing: border-box;
      text-align: right;
    }

    .ddp-type-selectbox {
      float: left;
      width: 55%;
      border: none;
      border-left: 1px solid #ddd;
      box-sizing: border-box;
    }
  }
}

.ddp-form-multy {
  position: relative;
  padding-left: 60px;
  margin-top: 4px;

  span.ddp-txt-label {
    position: absolute;
    top: 8px;
    left: 0;
    color: #90969f;
    font-size: 12px;
  }

  .ddp-box-multy {
    top: 2px;
    border: 1px solid #d0d1d8;
    background-color: #fff;

    input.ddp-input-typebasic {
      float: left;
      width: 35%;
      text-align: right;
      box-sizing: border-box;
      border: none;
    }

    .ddp-type-selectbox {
      float: left;
      width: 65%;
      box-sizing: border-box;
      border: none;
      border-left: 1px solid #d0d1d8;
    }

    &.ddp-form-time {
      .ddp-input-typebasic {
        width: 69px;
      }

      .ddp-type-selectbox {
        width: 100px;
      }
    }
  }
}

/**************************************************************
    range
**************************************************************/

.ddp-wrap-range {
  margin-top: -10px;

  .ddp-data-range {
    padding-top: 0;

    .ddp-bar {
      width: 6%;
      float: left;
      line-height: 30px;
      text-align: center;
    }

    .ddp-box-option-input {
      float: left;
      width: 47%;
      margin-left: 0;
      box-sizing: border-box;
    }

    input {
      width: 100%;
      text-align: center;
    }
  }
}

/**************************************************************
    column option
**************************************************************/

.ddp-wrap-column-option {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 14px 0 3px 14px;
  border-top: 1px solid #d7d8dd;
  background-color: #f6f6f7;

  .ddp-fold-link {
    display: inline-block;
    margin-bottom: 9px;
    color: #4c515a;
    font-size: 13px;
    text-decoration: underline;

    &:after {
      display: inline-block;
      width: 7px;
      height: 4px;
      margin-left: 10px;
      background: url('/assets/bi/images/icon_select.png') no-repeat;
      background-position: -16px top;
      vertical-align: middle;
      content: '';
    }
  }

  .ddp-wrap-fold.ddp-selected .ddp-fold-link:after {
    transform: rotate(180deg);
  }

  .ddp-fold-det {
    display: none;
  }

  .ddp-wrap-fold.ddp-selected .ddp-fold-det {
    display: block;
  }

  .ddp-wrap-edit3 {
    display: inline-block;
    float: left;
    width: auto;

    + .ddp-wrap-edit3 {
      margin-left: 19px;
    }

    &.ddp-type {
      .ddp-label-type {
        font-size: 13px;
        width: auto;
      }

      .ddp-txt-error {
        margin-left: 5px;
      }
    }

    .ddp-ui-edit-option .ddp-input-apply {
      width: 169px;
    }
  }
}
