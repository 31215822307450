/**
 * rome - Customizable date (and time) picker. Opt-in UI, no jQuery!
 * @version v2.1.22
 * @link https://github.com/bevacqua/rome
 * @license MIT
 */
.rd-date * {
  font-size: 15px;
}
.rd-container {
  display: none;
  border: 1px solid #333;
  background-color: #fff;
  padding: 10px;
  text-align: center;
}
.rd-container-attachment {
  position: absolute;
}
.rd-month {
  display: inline-block;
  margin-right: 25px;
}
.rd-month:last-child {
  margin-right: 0;
}
.rd-back,
.rd-next {
  cursor: pointer;
  border: none;
  outline: none;
  background: none;
  padding: 0;
  margin: 0;
}
.rd-back[disabled],
.rd-next[disabled] {
  cursor: default;
}
.rd-back {
  float: left;
}
.rd-next {
  float: right;
}
.rd-back:before {
  display: block;
  content: '\2190';
}
.rd-next:before {
  display: block;
  content: '\2192';
}
.rd-day-body {
  cursor: pointer;
  text-align: center;
}
.rd-day-selected,
.rd-time-selected,
.rd-time-option:hover {
  cursor: pointer;
  background-color: #333;
  color: #fff;
}
.rd-day-prev-month,
.rd-day-next-month {
  color: #999;
}
.rd-day-disabled {
  cursor: default;
  color: #fcc;
}
.rd-time {
  position: relative;
  display: inline-block;
  margin-top: 5px;
  min-width: 80px;
}
.rd-time-list {
  display: none;
  position: absolute;
  overflow-y: scroll;
  max-height: 160px;
  left: 0;
  right: 0;
  background-color: #fff;
  color: #333;
}
.rd-time-selected {
  padding: 5px;
}
.rd-time-option {
  padding: 5px;
}
.rd-day-concealed {
  visibility: hidden;
}
