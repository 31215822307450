:root {
  --selfai-text-color: var(--text-color);
  --selfai-secondary-text-color: #fff;
  --selfai-primary-text-color: #fff;
}

:root {
  --selfai-font-size-xs: 0.8125rem/1.25rem;
}

.p-confirm-popup-message {
  white-space: pre-line;
}